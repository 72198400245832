<template>
  <div class="bg-box">
    <img style="width: 100%" src="../../assets/img/banner.png" alt="" />
    <div class="w">
      <div class="works-top">
        <div class="title">
          <div></div>
          <div>兼职测试</div>
        </div>
      </div>
      <div class="download">
        <div class="down-title">
          <img src="../../assets/img/icon.png" alt="" />
          <div>实操测试题</div>
        </div>
        <div class="down-box">
          <div class="down-left">
            <img src="../../assets/img/dow.png" alt="" />
            <div class="left-mb" v-if="downInfo.name">
              <div>{{ downInfo.name }}</div>
              <div>{{ statustext}}</div>
            </div>
            <!-- <div class="left-mb" v-else>
              <div>已提交成功</div>
            </div> -->
          </div>
          <div class="down-btn" @click="handledownload">
          <!-- <div class="down-btn"> -->
            <i class="el-icon-download"></i>
            <div>
              下载
              <!-- <a target="_blank" :href="downInfo.download_url" download="">下载</a> -->
            </div>
          </div>
        </div>
      </div>

      <div class="uploading">
        <div class="down-box" style="margin-bottom: 20px">
          <div class="down-left">
            <img src="../../assets/img/up-works.png" alt="" />
            <div class="left-mb">
              <div>上传实操测试题</div>
              <div>格式：压缩包</div>
            </div>
          </div>
          <div v-if="show">
            <el-upload
              ref="upload"
              class="upload-demo"
              name="file"
              :http-request="ploadSuccess"
              action
              :show-file-list="false"
            >
              <div class="down-btn">
                <i class="el-icon-upload2"></i>
                <div>上传</div>
              </div>
            </el-upload>
          </div>
          <div class="down-btn" style="border: 0" v-else>
            <i class="el-icon-loading"></i>
            <div>文件上传中...</div>
          </div>
        </div>
        <div class="url-name" v-show="url">
          <div class="dian">
            {{ url }}
          </div>
          <i @click="handledels" class="el-icon-close"></i>
        </div>
        <div class="commit-btn" @click="handleCommit()">提交</div>
      </div>
    </div>
    <div style="height: 300px"></div>
    <!-- 完成上传 -->
    <div class="popup">
      <el-dialog
        :show-close="false"
        :visible.sync="indexDialogVisible"
        width="500px"
        center
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <div class="img-box-popup">
          <div class="del-close">
            <i class="el-icon-close" @click="indexDialogVisible = false"></i>
          </div>
          <img
            style="width: 170px; height: 123px"
            src="../../assets/img/homeyes.png"
            alt=""
          />
          <div class="close-font" style="color: #fe8220">
            您的测试已提交，等待审核中！
          </div>
          <div class="index-btn" @click="handleIndex()">返回首页</div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
let OSS = require("ali-oss");
let client = new OSS({
  region: "oss-cn-beijing", // 云账号AccessKey有所有API访问权限，建议遵循阿里云安全最佳实践，创建并使用STS方式来进行API访问
  accessKeyId: "LTAI4FnYYhr6cwpwGkg5Bks7",
  accessKeySecret: "4wAofXRnouwVWwdODH106eNQnFwi9k",
  stsToken: "",
  bucket: "mjshomeworkfiles",
});
import {
  get_practice_examples_url,
  sub_practice_examples,
} from "../../assets/api/index";
import { getStore } from "../../assets/js/utils";
export default {
  data() {
    return {
      indexDialogVisible: false,
      objectKey: "",
      show: true,
      url: "",
      downInfo: {},
      statustext:''
    };
  },
  mounted() {
    this.getPracticeExamplesUrl();
  },
  methods: {
    handleIndex() {
      this.$router.replace({ path: "/" });
    },
    // 判断地址
    is_img_url(imgurl) {
      // console.log(imgurl)
      return new Promise(function (resolve, reject) {
        var ImgObj = new Image();
        ImgObj.src = imgurl;
        console.log(ImgObj)
        ImgObj.onload = function (res) {
          resolve(res);
        };
        ImgObj.onerror = function (err) {
          reject(err);
        };
      });
    },
    // 点击提交按钮
    async handleCommit() {
      if (this.url) {
        let params = {
          test_record_id: this.$route.params.id,
          works_link: this.url,
        };
        let res = await sub_practice_examples(params);
        if (res.code == 200) {
          this.indexDialogVisible = true;
          this.url = "";
        } else {
          this.$message.error(res.msg);
          this.handledels(); // 清除未上传
        }
      } else {
        this.$message.error("请上传文件!");
      }
    },
    handledownload() {
      if (this.downInfo.download_url) {
        window.open(this.downInfo.download_url);
      } else {
        this.$message.error("下载失败!");
      }
    },
    // 获取url
    async getPracticeExamplesUrl() {
      let params = {
        test_record_id: this.$route.params.id,
      };
      let res = await get_practice_examples_url(params);
      if (res.code == 200) {
        // window.open()
        // console.log(data.infor)
        this.downInfo = res.data;
        if(this.downInfo.status=='-1'){
          this.statustext = '未提交'
        }else if(this.downInfo.status=='0'){
          this.statustext = '审核中'
        }else if(this.downInfo.status=='1'){
          this.statustext = '审核通过'
        }else if(this.downInfo.status=='2'){
          this.statustext = '审核失败'
        }
        
      }
    },
    handledels() {
      client.delete(this.objectKey).then((res) => {
        console.log("清除");
        this.url = "";
      });
    },
    ploadSuccess(file) {
      this.show = false;
      if (this.url) {
        this.handledels();
      }
      let fileName = file.file.name.split(".");

      this.show = false;
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let OMonth = this.padLeftZero(month);
      let objectKey = `${year}/${OMonth}/${
        fileName[0] + date.getTime() + "." + fileName.slice(-1)[0]
      }`;

      this.objectKey = objectKey;
      let urls = objectKey;
      // client.setHeader("Content-Disposition","attachment");

      let headerName =
        fileName[0] + date.getTime() + "." + fileName.slice(-1)[0];
      client
        .multipartUpload(urls, file.file, {
          progress: (p) => {},
          headers: {
            // 配置请求头
            "Content-Disposition": `attachment; filename=${encodeURIComponent(
              headerName
            )}`,
          },
        })
        .then((result) => {
          let resUrl = result.res.requestUrls[0];
          let url = resUrl.split("?")[0];
          //   var dom = url.split(".").slice(-1)[0];
          // console.log(url)
          console.log(url)
          this.url = url;
          // this.is_img_url(url)
          //   .then(() => {
          //   })
          //   .catch(() => {
          //     this.$message({
          //       message: "上传无效，请重新上传",
          //       type: "error",
          //     });
          //   });

          this.show = true;
        });
    },
    padLeftZero(str) {
      return ("00" + str).substr(str.toString().length);
    },
  },
};
</script>

<style scoped>
.url-name {
  margin: 0 auto;
  width: 637px;
  display: flex;
  justify-content: space-between;
}
.url-name i {
  cursor: pointer;
  padding-left: 20px;
}
.url-name i:hover {
  color: #f00;
}
.url-name div {
  color: #999999;
  font-size: 14px;
  width: 300px;
}
.commit-btn {
  margin: 60px auto 35px;
  width: 204px;
  height: 46px;
  line-height: 46px;
  font-size: 20px;
  color: #fff;
  background-color: #f28a41;
  text-align: center;
  border-radius: 23px;
  cursor: pointer;
}
.uploading {
  width: 803px;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  margin: 30px auto 0;
}
.down-btn {
  position: relative;
  width: 110px;
  height: 26px;
  border: 1px solid #fbad51;
  border-radius: 5px;
  cursor: pointer;
  line-height: 26px;
  color: #fbad51;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.down-btn a{
  display: inline-block;
  width: 110px;
  text-align: center;
  color: #fbad51;
  font-size: 16px;
  padding-left: 10px;
  box-sizing: border-box;
}
.ali{
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translateY(-50%);
}
.down-box {
  padding: 0 80px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
}
.left-mb {
  margin-left: 18px;
}
.left-mb div {
  color: #999999;
  font-size: 16px;
}
.left-mb div:nth-child(2) {
  margin-top: 13px;
}
.down-left {
  display: flex;
  align-items: center;
}
.down-title {
  width: 100%;
  background-color: #fbad51;
  color: #fff;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.down-title div {
  font-size: 24px;
  margin-left: 10px;
}
.download {
  width: 803px;
  height: 290px;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  margin: 60px auto 0;
}
.works-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}
.title {
  display: flex;
  align-items: center;
}
.title div:nth-child(1) {
  width: 8px;
  height: 24px;
  background-color: #fbad51;
}
.title div:nth-child(2) {
  font-size: 20px;
  color: #000;
  margin-left: 10px;
  font-weight: 600;
}
</style>
